










import { Component, Vue, Prop } from 'vue-property-decorator';
import { FileDownload } from '@/types/cms/components';

/**
 * CMS file downlaod component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentFileDownload extends Vue {
    // Prop with file data
    @Prop({ type: Object as () => FileDownload, required: true }) readonly fileData: FileDownload | undefined;

    /*
     * @returns file url
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get fileUrl(): string {
        if (!this.fileData) return '';

        return process.env.VUE_APP_API_URL + this.fileData.fileUrl;
    }
}
